<template>
  <div>
    <v-card flat class="mt-4">
      <v-row no-gutters>
        <v-col cols="12" md="12" sm="12" lg="12" xl="12">
          <v-toolbar dense elevation="0">
            <v-autocomplete
              label="Filter by Location"
              dense
              outlined
              item-text="location_name"
              item-value="location_id"
              :disabled="isLoading == true"
              style="max-width: 200px"
              :items="locationItems"
              @change="get_scanlogs_pagination()"
              v-model="LocationData"
              class="mt-6 mr-2"
            ></v-autocomplete>
            <v-dialog
              ref="dialog1"
              v-model="modal"
              color="#f4813f"
              :return-value.sync="date"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="computedDateFormatted"
                  dense
                  outlined
                  class="mt-6 ml-2"
                  persistent-hint
                  :disabled="isLoading == true"
                  label="From Date"
                  color="#f4813f"
                  append-icon="mdi-calendar"
                  style="max-width: 150px"
                  @click:append="date ? getcal() : getcal()"
                  v-bind="attrs"
                  v-on="on"
                  readonly
                >
                </v-text-field>
              </template>
              <v-date-picker
                :min="minmonth"
                :max="new Date().toISOString().substr(0, 10)"
                v-model="date"
                color="#f4813f"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn text color="#f4813f" @click="modal = false">
                  Cancel
                </v-btn>
                <v-btn
                  v-on:click="get_scanlogs_pagination()"
                  text
                  color="#f4813f"
                  @click="$refs.dialog1.save(date)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
            <v-dialog
              ref="dialog"
              v-model="endmodal"
              color="#f4813f"
              :return-value.sync="todate"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dataFormatted"
                  :disabled="isLoading == true"
                  dense
                  outlined
                  class="mt-6 ml-2"
                  persistent-hint
                  label="To Date"
                  color="#f4813f"
                  append-icon="mdi-calendar"
                  style="max-width: 150px"
                  @click:append="todate ? gettimecal() : gettimecal()"
                  v-bind="attrs"
                  v-on="on"
                  readonly
                >
                </v-text-field>
              </template>
              <v-date-picker
                :max="new Date().toISOString().substr(0, 10)"
                :min="date"
                v-model="todate"
                color="#f4813f"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn text color="#f4813f" @click="endmodal = false">
                  Cancel
                </v-btn>
                <v-btn
                  v-on:click="get_scanlogs_pagination()"
                  text
                  color="#f4813f"
                  @click="$refs.dialog.save(todate)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
            <v-text-field
              v-model="search"
              label="Search"
              style="max-width: 220px"
              dense
              append-icon="mdi-magnify"
              class="mt-4 ml-2"
            ></v-text-field>
            <v-spacer />
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <v-btn
                  @click="open_data_correction_dialog()"
                  v-on="on"
                  small
                  class="gradient-bg white--text mt-n1"
                >
                  <v-icon class="mr-2">mdi-table-edit</v-icon>Add Data
                  Correction
                </v-btn>
              </template>
              <span class="white--text"> Add Data Correction </span>
            </v-tooltip>
          </v-toolbar>
        </v-col>
      </v-row>
      <v-card-text>
        <v-data-table
          :headers="dataCorrectionHeaders"
          :items="DataCorrectionItems"
          dense
          :height="height"
          :loading="correctionLoading"
          :search="search"
          loading-text="Loading... Please wait"
          class="overflow-y-auto overflow"
          :options.sync="pagination"
          @update:options="getVerifyPayPagination()"
          :fixed-header="fixed"
          :footer-props="{
            'items-per-page-options': [10, 15, 20, 25],
          }"
        >
          <template v-slot:no-data>
            <v-alert :value="true" class="gradient-bg2 white--text">
              No Data available.
            </v-alert>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" class="gradient-bg2 white--text">
              No records found!
            </v-alert>
          </template>
          <template v-slot:[`item.swipe_date`]="{ item }">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <span
                  v-on="on"
                  class="overflow"
                  v-text="get_date_only(item.swipe_time_stamp)"
                ></span>
                <br />
                <span
                  v-on="on"
                  class="overflow"
                  v-text="get_time_only(item.swipe_time_stamp).split(',')[1]"
                ></span>

                <br />
              </template>
              <span class="white--text">{{
                get_date(item.swipe_time_stamp)
              }}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.user_name`]="{ item }">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <div class="overflow" v-on="on">{{ item.user_name }}</div>
                <br />
                <div class="overflow" v-on="on">{{ item.employee_id }}</div>
              </template>
              <span class="white--text">{{ item.user_name }}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.box_location`]="{ item }">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <div class="overflow" v-on="on">
                  {{
                    item.box_location.length >= 8
                      ? item.box_location.substr(0, 8) + "..."
                      : item.box_location
                  }}
                </div>
              </template>
              <span class="white--text">{{ item.box_location }}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.corrected_by`]="{ item }">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <div class="overflow" v-on="on">{{ item.corrected_by }}</div>
              </template>
              <span class="white--text">{{ item.corrected_by }}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.comment`]="{ item }">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <div class="overflow" v-on="on">{{ item.comment }}</div>
              </template>
              <span class="white--text">{{ item.comment }}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.swipe_data_corrected_on`]="{ item }">
            <span v-text="get_date(item.swipe_data_corrected_on)"></span>
            <br />
            <span v-if="item.acted_on == undefined" class="ml-8">-</span>
            <span v-else v-text="get_date(item.acted_on)"></span>
          </template>
          <template v-slot:[`item.acted_on`]="{ item }">
            <span v-if="item.acted_on == undefined">-</span>
            <span v-else v-text="get_date(item.acted_on)"></span>
          </template>

          <template v-slot:[`item.approval_status`]="{ item }">
            <span
              v-show="item.approval_status == 'APPROVED'"
              style="color: green"
            >
              {{ item.approval_status }}</span
            >
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <v-icon
                  small
                  v-on="on"
                  depressed
                  style="color: orange"
                  @click="Approve(item)"
                  mediumapproved_on
                  class="ml-2"
                  v-show="item.approval_status == 'PENDING'"
                >
                  mdi-account-alert
                </v-icon>
              </template>
              <span class="white--text">PENDING </span>
            </v-tooltip>
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <v-icon
                  small
                  v-on="on"
                  depressed
                  style="color: red"
                  medium
                  class="ml-2"
                  v-show="item.approval_status == 'REJECTED'"
                >
                  mdi-cancel
                </v-icon>
              </template>
              <span class="white--text">REJECTED </span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.approver_email_id`]="{ item }">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <div v-if="item.approver_email_id" v-on="on">
                  {{
                    item.approver_email_id.length >= 8
                      ? item.approver_email_id.substr(0, 8) + "..."
                      : item.approver_email_id
                  }}
                </div>
                <div v-else>-</div>
              </template>
              <div class="caption" style="max-width: 250px">
                {{ item.approver_email_id }}
              </div>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <AddDataCorrecion
      :addDataCorrectionDialog="addDataCorrectionDialog"
      @close_data_correction_dialog="addDataCorrectionDialog = false"
      @call_data_correction="call_dt"
    />
    <Overlay :overlay="overlay" />
    <div v-if="component_check == 1">
      <ApproveDatacorrection
        :approvaldatacorrection="approvaldatacorrection"
        :approvaldta="approvaldta"
        @clicked="close_prop"
        v-on:errorMsg="errorPop"
        v-on:successMsg="sucessPop"
      />
      <Snackbar :SnackBarComponent="SnackBarComponent" />
    </div>
  </div>
</template>

<script>
import { GetLocation } from "@/mixins/GetLocationList.js";
import moment from "moment";
import AddDataCorrecion from "@/components/Dialogs/AddDataCorrection.vue";
import Snackbar from "@/components/SnackBar.vue";
import Overlay from "@/components/Overlay.vue";
import { API, graphqlOperation } from "aws-amplify";
import { GetSwipesForOrganisations } from "@/graphql/queries.js";
import ApproveDatacorrection from "../../components/Dialogs/ApproveDatacorrection.vue";
export default {
  components: {
    AddDataCorrecion,
    Snackbar,
    Overlay,
    ApproveDatacorrection,
  },
  mixins: [GetLocation],
  data: () => ({
    pagination: {
      itemsPerPage: 99,
      page: 1,
    },
    search: "",
    fixed: true,
    endmodal: false,
    approvaldta: {},
    SnackBarComponent: {},
    modal: false,
    component_check: 0,
    approvaldatacorrection: false,
    overlay: false,
    LocationData: "ALL",
    locationItems: [],
    correctionLoading: false,
    addDataCorrectionDialog: false,
    date:  new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString().substr(0, 10),
    todate: new Date().toISOString().substr(0, 10),
    dataCorrectionHeaders: [
      { text: "Member Name /ID", value: "user_name" },
      // { text: "Member Id", value: "employee_id" },
      { text: "Location Name", value: "box_location" },
      { text: "Corrected For", value: "swipe_date" },
      // { text: "Updated By", value: "corrected_by" },
      { text: "Initiated On/Acted On", value: "swipe_data_corrected_on" },
      { text: "Approved By", value: "approver_email_id" },
      // { text: "Acted On", value: "acted_on" },
      { text: "Approval Status", value: "approval_status" },
      { text: "Comments", value: "comment" },
    ],
    DataCorrectionItems: [],
    height: 0,
    nextToken: "",
    minmonth: "",
    selectedDate: "",
    isLoading: false,
  }),
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
    dataFormatted() {
      return this.dateformating(this.todate);
    },
  },
  async created() {
    this.height = window.innerHeight - 210;
    this.selectedDate = this.get_orgdate(
      this.$store.getters.get_org_details[0].organisation_created_on
    );
    this.minmonth = moment(this.selectedDate, "MM/DD/YYYY").format(
      "YYYY-MM-DD"
    );
    await this.GetLocation();
    await this.fetch_deatils();
    await this.get_data_correction();
    // console.log(this.$store.getters.get_org_user_details[0].user_email_id);
  },
  methods: {
    get_scanlogs_pagination() {
      this.next_token = null;
      this.DataCorrectionItems = [];
      this.get_data_correction();
    },
    fetch_deatils() {
      this.GetLocationitems.forEach((element) => {
        this.locationItems.push({
          location_name: element.loc_name,
          location_id: element.loc_id,
        });
      });
      this.locationItems.unshift("ALL");
    },
    get_orgdate(date) {
      let a = new Date(date * 1000).toLocaleTimeString("en-US", {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
      return a.toUpperCase();
    },
    gettimecal() {
      this.endmodal = true;
    },
    dateformating(todate) {
      if (!todate) return null;
      const [year, month, day] = todate.split("-");
      return `${day}/${month}/${year}`;
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    errorPop(val) {
      this.approvaldatacorrection = false;

      this.SnackBarComponent = {
        SnackbarVmodel: true,
        SnackbarColor: "red",
        Top: true,
        SnackbarText: val,
      };
      this.get_data_correction();
    },
    getcal() {
      this.modal = true;
    },
    sucessPop(val) {
      this.approvaldatacorrection = false;

      this.SnackBarComponent = {
        SnackbarVmodel: true,
        SnackbarColor: "green",
        Top: true,
        SnackbarText: val,
      };
      this.get_data_correction();
    },
    Approve(item) {
      this.component_check = 1;
      this.approvaldta = item;
      this.approvaldatacorrection = true;
    },
    close_prop(val) {
      if (val == 0) {
        this.approvaldatacorrection = false;
      }
    },
    get_date_only(date) {
      let a = new Date(date * 1000).toLocaleDateString("en-US", {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      const [month, day, year] = a.split("/");

      // Reformatting to "day month year" format
      return `${day}/${month}/${year}`;
    },
    get_time_only(date) {
      let a = new Date(date * 1000).toLocaleTimeString("en-US", {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
      return a.toUpperCase();
    },
    get_date(date) {
      const options = {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      };

      let a = new Date(date * 1000).toLocaleString("en-US", options);

      // Splitting the date into components
      const [datePart, timePart] = a.split(", ");
      const [month, day, year] = datePart.split("/");

      // Reformatting to "dd/mm/yyyy HH:MM:SS" format
      return `${day}/${month}/${year} ${timePart}`;
    },
    call_dt() {
      this.addDataCorrectionDialog = false;
      this.get_data_correction();
    },
    async get_data_correction() {
      this.overlay = true;
      this.correctionLoading = true;
      try {
        await API.graphql(
          graphqlOperation(GetSwipesForOrganisations, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
              nextToken: null,
              limit: 100,
              from_date: this.date,
              to_date: this.todate,
              location_id: this.LocationData,
            },
          })
        ).then((res) => {
          this.DataCorrectionItems =
            res.data.GetSwipesForOrganisations.data.filter(
              (val) => val.source == "DATACORRECTION"
            );
          // console.log("asd", this.DataCorrectionItems);
          this.next_token = res.data.GetSwipesForOrganisations.nextToken;
          this.$forceUpdate();
          this.overlay = false;
          this.correctionLoading = false;
        });
      } catch (err) {
        console.log("errrrr", err);
        this.overlay = false;
        this.correctionLoading = false;
      }
    },
    async getVerifyPayPagination() {
      try {
        if (this.next_token) {
          let result = await API.graphql(
            graphqlOperation(GetSwipesForOrganisations, {
              input: {
                organisation_id:
                  this.$store.getters.get_org_details[0].organisation_id,
                nextToken: this.next_token,
                limit: 100,
              },
            })
          );
          if (result.data.GetSwipesForOrganisations.data.length > 0) {
            var response = result.data.GetSwipesForOrganisations.data;
            this.DataCorrectionItems.concat(response);
            this.DataCorrectionItems = this.DataCorrectionItems.concat(
              result.data.GetSwipesForOrganisations.data
            );
            this.next_token = result.data.GetSwipesForOrganisations.nextToken;
            this.$forceUpdate();
          }
        }
      } catch (err) {
        this.DataCorrectionItems = [];
      }
    },
    open_data_correction_dialog() {
      this.addDataCorrectionDialog = true;
    },
  },
};
</script>

<style>
</style>