<template>
  <div>
    <v-dialog v-model="approvaldatacorrection" persistent width="470px">
      <v-card class="overflow-hidden">
        <v-toolbar dense class="gradient-bg elevation-0">
          <v-toolbar-title class="white--text"
            >Data Correction Approval</v-toolbar-title
          ><v-spacer />
          <v-icon @click="close_dialog()" class="white--text">mdi-close</v-icon>
        </v-toolbar>
        <v-card-text
          align="center"
          style="font-weight: bold; text-align: justify"
        >
          <div class="mt-5">Would you like to Approve DataCorrection for {{ approvaldta.user_name }} ?</div>
        </v-card-text>
        <div class="d-flex justify-center mb-3">
          <v-card-actions class="mt-6">
            <v-btn
              color="primary"
              class="white--text"
              @click="reject_datacorrection()"
              small
              >Reject</v-btn
            >
            <v-btn
              color="primary"
              class="white--text"
              @click="approvedata()"
              small
              >Approve</v-btn
            >
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { API, graphqlOperation } from "aws-amplify";
import { approval_for_datacorrection } from "@/graphql/mutations.js";
export default {
  props: {
    approvaldatacorrection: Boolean,
    approvaldta: Object,
  },
  data: () => ({}),
  methods: {
    async reject_datacorrection() {
      try {
        let result = await API.graphql(
          graphqlOperation(approval_for_datacorrection, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
              swipe_id: this.approvaldta.swipe_id,
              actions: "REJECTED",
              approver_email_id:
                this.$store.getters.get_org_user_details[0].user_email_id,
            },
          })
        );
        if (
          JSON.parse(result.data.approval_for_datacorrection).QRStatus ==
          "FAILURE"
        ) {
          this.$emit(
            "errorMsg",
            JSON.parse(result.data.approval_for_datacorrection).Message
          );
        }
        if (
          JSON.parse(result.data.approval_for_datacorrection).QRStatus ==
          "SUCCESS"
        ) {
          this.$emit(
            "successMsg",
            JSON.parse(result.data.approval_for_datacorrection).Message
          );
        }
      } catch (error) {
        this.$emit("errorMsg", error.errors[0].message);
        console.log(error);
      }
    },
    async approvedata() {
      try {
        let result = await API.graphql(
          graphqlOperation(approval_for_datacorrection, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
              swipe_id: this.approvaldta.swipe_id,
              actions: "APPROVED",
              approver_email_id:
                this.$store.getters.get_org_user_details[0].user_email_id,
            },
          })
        );
        if (
          JSON.parse(result.data.approval_for_datacorrection).QRStatus ==
          "FAILURE"
        ) {
          this.$emit(
            "errorMsg",
            JSON.parse(result.data.approval_for_datacorrection).Message
          );
        }
        if (
          JSON.parse(result.data.approval_for_datacorrection).QRStatus ==
          "SUCCESS"
        ) {
          this.$emit(
            "successMsg",
            JSON.parse(result.data.approval_for_datacorrection).Message
          );
        }
      } catch (error) {
        this.$emit("errorMsg", error.errors[0].message);
        console.log(error);
      }
    },
    close_dialog() {
      this.$emit("clicked", 0);
    },
  },
};
</script>

<style>
</style>